import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'

export default defineNuxtPlugin(async (nuxtApp) => {
  const isNative = Capacitor.isNativePlatform()
  const appFetch = $fetch.create({
    baseURL: nuxtApp.$config.public.baseUrlForMobile,
    referrerPolicy: 'unsafe-url', // potentially ca dégage
    async onRequest({ options }) {
      if (isNative) {
        options.headers = {
          ...options.headers,
          'X-App-Version': (await App.getInfo()).version,
          'X-App-Device': (await Device.getId()).identifier,
        }
      }
    },
    onResponse({ options }) {
      // console.log('LOVA onResponse', JSON.stringify(options))
      // set Manas in Preferences?
    },
  })
  return {
    provide: { appFetch },
  }
})
